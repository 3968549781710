.container {
  margin: var(--size-40);
}
.sidebarContainer {
  display: flex;
  gap: var(--size-24);
}

.sidebarContainer main {
  flex-grow: 8;
}

.sidebarContainer main:not(.site-main main) {
  padding-top: var(--size-24);
  min-width: 0;
}
