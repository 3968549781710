$headerHeight: calc(var(--size-64) + 1px);

.sidebar {
  display: none;

  @media (min-width: 769px) {
    display: flex;
    flex-basis: 260px;
    margin-right: 24px;
  }
}

.sidebarScrollable {
  position: sticky;
  top: $headerHeight;
  min-width: 260px;
  height: calc(100vh - $headerHeight);
  padding: var(--site-vertical-padding) 0;
  overflow-y: auto;
}

.subMenu {
  margin-left: 1rem;
}

.menuItem {
  display: inline-block;
  padding: 6px 0;
  font-size: 1rem;
  line-height: 1.25;
}

.link {
  text-decoration: none !important;
  font-weight: 400 !important;

  &.linkActive {
    font-weight: 600 !important;
  }

  &[disabled] {
    color: var(--color-content-tertiary);
  }
}

.group {
  color: var(--color-content-tertiary);
}

.hide {
  display: none;
}
